import React from 'react'
import { IntlProvider } from 'react-intl'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { ThemeProvider } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from './components/mui-dlvz-theme'
import i18nMessages from './data/messages/de.js'
import favicon from './assets/images/favicon.png'
import { metaData } from '../site-config'

import ASnackbarStore from './components/01-atoms/a-snackbar-store'
import Header from './components/03-organisms/o-header'
import Footer from './components/03-organisms/o-footer'

const styles = {
  '@global': {
    a: {
      color: 'inherit',
    },
  },
}

const Layout = function(props) {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <IntlProvider locale="de" messages={i18nMessages}>
        <div>
          <Helmet
            title={metaData.siteTitle}
            meta={[
              {
                name: 'description',
                content: metaData.siteDescription,
              },
              {
                name: 'keywords',
                content: metaData.siteKeywords,
              },
              {
                name: 'msvalidate.01',
                content: 'D85EB35200D924B45123D0C9F5AB6A59',
              },
            ]}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
            ]}
          />
          <Header />
          <ASnackbarStore />
          {props.children}
          <Footer />
        </div>
      </IntlProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default withStyles(styles)(Layout)
