// import request from './fakeRequest'
import axios from 'axios'

// TODO: set to false for production
axios.defaults.withCredentials = true

let localStorage

// If we're testing or gatsby is building, use a local storage polyfill
// gatsby build has no global.window object, therefore the test if it is defined
if (
  (global.process && process.env.NODE_ENV === 'test') ||
  typeof global.window === `undefined`
) {
  localStorage = require('localStorage')
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage
}

const auth = {
  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  login(username, password) {
    if (auth.loggedIn()) return Promise.resolve(true)

    // return false
    return axios({
      method: 'post',
      url: '/api/auth/token/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username: username,
        password: password,
      },
    })
      .then(response => {
        localStorage.userData = JSON.stringify(response.data)
        return response.data
      })
      .catch(error => {
        return error
      })

    // Post a fake request
    // return request.post('/login', { username, password }).then(response => {
    //   // Save token to local storage
    //   localStorage.token = response.token
    //   return Promise.resolve(true)
    // })
  },
  /**
   * Gets user infos by username and save it to localStorage
   *
   * @param  {string} username The username of the user
   *
   * @return {object|boolean} object with user infos { user_id: 'xx', company_id: 'yy' } or false if not found
   */
  getUserInfos(userid, token) {
    // TODO: secure this request via token
    return axios({
      method: 'get',
      url: '/api/user/' + userid + '/',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': 'Bearer ' + token,
      },
    })
      .then(response => {
        // localStorage.userData = JSON.stringify(response.data)
        let userData = JSON.parse(localStorage.userData)
        // userData.user_id = response.data.user_id
        // userData.company_id = response.data.company_id
        userData.status = response.data.status

        localStorage.userData = JSON.stringify(userData)
        return userData
      })
      .catch(error => {
        return error
      })

    // Post a fake request
    // return request.post('/login', { username, password }).then(response => {
    //   // Save token to local storage
    //   localStorage.token = response.token
    //   return Promise.resolve(true)
    // })
  },
  /**
   * Logs the current user out
   */
  logout() {
    delete localStorage.userData
    // TODO: check if the token should be destroyed via JWT
    return true
  },
  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    // const validate = axios({
    //   method: 'post',
    //   url: '/api/auth/token/validate/',
    //   headers: {
    //     // 'Content-Type': 'application/json',
    //     'X-Authorization':
    //       'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kbHZ6LnFhLndwLnNwYy5ubWcuZG1vcC5kZSIsImlhdCI6MTUyNzgwMTYxMSwibmJmIjoxNTI3ODAxNjExLCJleHAiOjE1Mjg0MDY0MTEsImRhdGEiOnsidXNlciI6eyJpZCI6IjgzNCJ9fX0.-Zc7fcY3M1MfPry0BrVlaL-5uWf1int-9eLdmA5xZlA',
    //   },
    // }).then(response => {
    //   console.log('axios loggedIn() then', response)
    //   localStorage.token = response.data.token
    //   return response
    // })

    if (typeof localStorage.userData == 'string') {
      // validate token
      const userData = JSON.parse(localStorage.userData)

      return typeof userData.token == 'string' ? userData : false
    }

    return false
  },
  /**
   * Registers a user
   * @param  {string} username The username of the user
   * @param  {string} company The name of the company
   */
  register(username, company, package_type) {
    // Post a fake request
    // return request.post('/register', { username, company }).then(response => {
    //   return Promise.resolve(true)
    // })

    // TODO: use axios
    return axios({
      method: 'post',
      url: '/api/user/',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        email: username,
        companyname: company,
        packagetype: package_type,
      },
    })
      .then(response => {
        // console.log('fetch /api/user/', response)

        return response
      })
      .catch(error => {
        // console.log('fetch /api/user/ fail', error.response)

        return error
      })
  },
  /**
   * Subscribes a user
   * @param  {string} userId The users id
   * @param  {string} token The users JWT token
   */
  subscribe(userId, token) {
    // Post a fake request
    // return request.post('/register', { username, company }).then(response => {
    //   return Promise.resolve(true)
    // })

    // TODO: use axios
    return axios({
      method: 'post',
      url: '/api/user/' + userId + '/subscribe/',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': 'Bearer ' + token,
      },
    })
      .then(response => {
        // localStorage.userData = JSON.stringify(response.data)
        let userData = JSON.parse(localStorage.userData)
        userData.status.registration = 'pending'

        localStorage.userData = JSON.stringify(userData)

        return response
      })
      .catch(error => {
        // console.log('fetch /api/user/ fail', error.response)

        return error
      })
  },
  onChange() {},
}

export default auth
