/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import ReactDOM from 'react-dom'

import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'

import Layout from './src/layout'

import reducer from './src/redux/reducers.js'
import rootSaga from './src/redux/sagas/index.js'
import { clearApiResults, clearMessage } from './src/actions'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(reducer, applyMiddleware(sagaMiddleware))

// Don't delete even if its an empty return. Otherwise the babel-polyfill
// won't be available
export const onClientEntry = () => {}

// wrap base router with Provider
// @url https://github.com/gatsbyjs/gatsby/blob/master/examples/using-redux/gatsby-browser.js
export const wrapRootElement = ({ element }) => {
  sagaMiddleware.run(rootSaga)

  const ConnectedRootElement = <Provider store={store}>{element}</Provider>

  return ConnectedRootElement
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const WrappedPageElement = <Layout {...props}>{element}</Layout>
  return WrappedPageElement
}

export const onRouteUpdate = () => {
  // clear API results for every route change
  store.dispatch(clearApiResults())
  store.dispatch(clearMessage())
}

// https://github.com/gatsbyjs/gatsby/issues/17914
export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
