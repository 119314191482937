import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

/** AButton */
class AButton extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      children,
      to,
      type,
      variant,
      label,
      disabled,
      loading,
      color,
      ...rest
    } = this.props

    return (
      <Button
        href={to}
        type={type ? type : 'button'}
        variant={variant}
        label={label}
        color={color}
        disabled={disabled}
        {...rest}
      >
        {!loading && children}
        {loading && <CircularProgress size={24} />}
      </Button>
    )
  }
}

AButton.propTypes = {
  /** The buttons children nodes */
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  /** shows process spinner if set to true, will also disable the button whilte loading is true */
  loading: PropTypes.bool,
  /** Target of this Button. External links will be opened in a new window. Internal links (starting with /) will use the gatsby-link component. */
  to: PropTypes.string,
  /** The type of button. Standard is "button" but it could be "submit" if used in form */
  type: PropTypes.string,
  /** "raised" buttons have a depth and a shadow */
  variant: PropTypes.string,
  /** Color of button e.g primary orsecondary */
  color: PropTypes.string,
}

// Specifies the default values for props:
AButton.defaultProps = {
  loading: false,
  disabled: false,
}

export default AButton
