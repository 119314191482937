import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import withStyles from '@material-ui/styles/withStyles'

import { getMessageAndVariant } from '../../data/messages'
import { connect } from 'react-redux'
import { clearMessage } from '../../actions'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: theme.palette.getContrastText(
        theme.customStyles.extraColors.darkGrey
      ),
    },
  },
})

function ASnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        </span>
      }
      {...other}
    />
  )
}

ASnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

const ASnackbarContentWrapper = withStyles(styles1)(ASnackbarContent)

class ASnackbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return
    }
    this.setState(prevState => ({
      ...prevState,
      open: false,
    }))

    this.props.clearMessage()
  }

  render() {
    const { messageCode, message, variant } = this.props

    let messageType = variant ? variant : 'info'

    if (messageCode) {
      var userMessageAndVariant = getMessageAndVariant(messageCode)
      var userMessage = userMessageAndVariant.message

      messageType = userMessageAndVariant.variant
    }
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.state.open}
        onClose={this.handleClose}
      >
        <ASnackbarContentWrapper
          onClose={this.handleClose}
          variant={messageType}
          message={message ? message : userMessage}
        />
      </Snackbar>
    )
  }
}

ASnackbar.propTypes = {
  messageCode: PropTypes.node,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  clearMessage: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    clearMessage: () => dispatch(clearMessage()),
  }
}

export default connect(null, mapDispatchToProps)(ASnackbar)
