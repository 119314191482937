/**
 * MMenu
 *
 * @param {array} entries Entries should be an array of Objects.
 *    Each Object should have the following items:
 *    {
 *      'label': 'The link label',
 *      'target': 'The links href'
 *    }
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MenuList from '@material-ui/core/MenuList'
import withStyles from '@material-ui/styles/withStyles'
import classNames from 'classnames'

import ALink from '../01-atoms/a-link.js'

const styles = theme => ({
  menuList: {
    padding: 0,
  },
  link: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    color: theme.customStyles.extraColors.darkGrey,
    textDecoration: 'none',
    display: 'inline-block',
    outline: 'none',
    '&:hover': {
      color: theme.palette.highlightButton.main,
    },
  },
  activeLink: {
    color: theme.palette.highlightButton.main,
  },
})

class MMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, className, activeclassname } = this.props

    const MenuEntries =
      typeof this.props.entries == 'object' && this.props.entries.length
        ? this.props.entries.map((link, index) => {
            return (
              <ALink
                key={index}
                to={link.target}
                noButton
                className={classNames(classes.link, className)}
                activeclassname={classNames(
                  classes.activeLink,
                  activeclassname
                )}
              >
                {link.label}
              </ALink>
            )
          })
        : 'Please define your array of menu entries'

    return <MenuList className={classes.menuList}>{MenuEntries}</MenuList>
  }
}

MMenu.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  activeclassname: PropTypes.string,
}

export default withStyles(styles)(MMenu)
