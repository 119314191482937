import { all, call, put, take } from 'redux-saga/effects'
import auth from '../../auth'
import apiCalls from '../apiCalls'

import {
  SENDING_REQUEST,
  SUBSCRIBE_SET,
  SET_API_RESULTS,
  REQUEST_ERROR,
  CLEAR_ERROR,
  PROFILE_REQUEST,
  PROFILE_REQUEST_SET,
  PROFILE_UPDATE,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from '../../actions/constants'

/**
 * Effect to fetch profile informations via API
 * @param  {string} userId The users id
 */
export function* profileGet({ companyId, userId, token }) {
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })

  // We then try to register or log in the user, depending on the request
  try {
    yield put({ type: CLEAR_ERROR })
    // const response = yield call(apiCalls.getCompany, userId)

    // first get user Infos
    const userInfo = yield call(auth.getUserInfos, userId, token)

    // Throw Error if response has failed
    if (userInfo instanceof Error) {
      throw userInfo
    }

    let profileData = ''

    if (userInfo.status.registration != 'active') {
      profileData = {
        user: userInfo,
        queries: false,
      }
    }

    const [categories, company, settings, tags] = yield all([
      call(apiCalls.getCategory, {}, { profile: true }),
      call(apiCalls.getCompany, companyId),
      call(apiCalls.getSettings, {}),
      call(apiCalls.getTag, {}, { profile: true }),
    ])

    profileData = {
      user: userInfo,
      queries: {
        category: categories,
        company: company,
        settings: settings,
        tag: tags,
      },
    }

    if (typeof profileData == 'object') {
      // if queries are false, update dlvz status
      if (typeof profileData.user == 'object') {
        yield put({
          type: SUBSCRIBE_SET,
          newDLVZStatus: profileData.user.status.registration,
        })
      }

      if (typeof profileData.queries == 'object') {
        yield put({
          type: SET_API_RESULTS,
          results: profileData.queries,
        })
      }
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
    yield put({
      type: SET_API_RESULTS,
      results: false,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: SENDING_REQUEST, sending: false, lastCall: 'profileGet' })
  }
}

/**
 * Effect to send profile informations via API
 * @param  {Object} data The date we're updating
 */
export function* profileSet(request) {
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })

  // We then try to register or log in the user, depending on the request
  try {
    yield put({ type: CLEAR_ERROR })
    yield put({ type: CLEAR_MESSAGE })

    if (request.data.data.company_info.logo) {
      const requestData = request.data

      if (requestData.data.company_info.logo.status == 'deleted') {
        yield call(apiCalls.deleteMedia, {
          imageID: requestData.data.company_info.logo.id,
          token: requestData.token,
        })
        request.data.data.company_info.logo = ''
      }

      if (requestData.data.company_info.logo.status == 'new') {
        let formData = new FormData()
        formData.append('file', requestData.data.company_info.logo.file)
        formData.append('author', requestData.data.user_id)
        formData.append('post', requestData.companyId)
        const successfulAdded = yield call(apiCalls.addMedia, {
          token: requestData.token,
          data: formData,
        })

        request.data.data.company_info.logo = successfulAdded
      }
    }

    if (request.data.data.company_info.gallery) {
      const requestData = request.data

      yield all(
        request.data.data.company_info.gallery
          .filter(image => image.status == 'deleted')
          .map(image => {
            return call(apiCalls.deleteMedia, {
              imageID: image.id,
              token: requestData.token,
            })
          })
      )

      const successfulAdded = yield all(
        request.data.data.company_info.gallery
          .filter(image => image.status == 'new')
          .map(image => {
            let formData = new FormData()
            formData.append('file', image.file)
            formData.append('author', requestData.data.user_id)
            formData.append('post', requestData.companyId)
            return call(apiCalls.addMedia, {
              token: requestData.token,
              data: formData,
            })
          })
      )

      let images = requestData.data.company_info.gallery
        .filter(image => image.status != 'deleted' && image.status != 'new')
        .map(image => image.id)

      request.data.data.company_info.gallery = [...images, ...successfulAdded]
    }

    if (request.data.data.company_info.references) {
      const requestData = request.data

      // Remove images if
      // - reference deleted and had an image
      // - image in reference deleted
      yield all(
        request.data.data.company_info.references
          .filter(
            reference =>
              (reference.status == 'deleted' &&
                typeof reference.image == 'object') ||
              (typeof reference.image == 'object' &&
                reference.image.status == 'deleted')
          )
          .map(reference => {
            return call(apiCalls.deleteMedia, {
              imageID: reference.image.id,
              token: requestData.token,
            })
          })
      )

      // Add images if
      // - new reference with image added
      // - existing reference image changed
      const images = yield all(
        request.data.data.company_info.references
          .filter(reference => reference.status != 'deleted')
          .map(reference => {
            if (reference.image.status == 'new') {
              let formData = new FormData()
              formData.append('file', reference.image.file)
              formData.append('author', requestData.data.user_id)
              formData.append('post', requestData.companyId)

              return call(apiCalls.addMedia, {
                token: requestData.token,
                data: formData,
              })
            } else if (
              typeof reference.image == 'object' &&
              reference.image.status != 'deleted'
            ) {
              return reference.image.id
            } else {
              return ''
            }
          })
      )

      // Connect image_ids with references
      let references = request.data.data.company_info.references
        .filter(reference => reference.status != 'deleted')
        .map((reference, i) => {
          return {
            ...reference,
            image: images[i],
          }
        })

      request.data.data.company_info.references = references
    }

    const response = yield call(apiCalls.setCompany, request.data)

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }

    yield put({ type: PROFILE_UPDATE, updatedFields: response })

    yield put({
      type: USER_MESSAGE,
      userMessage: {
        code: 'save_success',
        message: 'Änderung erfolgreich gespeichert',
      },
    })

    return true
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })

    return false
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: SENDING_REQUEST, sending: false, lastCall: 'profileSet' })
  }
}

/**
 * Profile saga
 */
export function* profileGetFlow() {
  while (true) {
    // We always listen to `PROFILE_REQUEST` actions
    const request = yield take(PROFILE_REQUEST)
    yield call(profileGet, request.data)
  }
}

/**
 * Profile Edit saga
 */
export function* profileSetFlow() {
  while (true) {
    // We always listen to `PROFILE_REQUEST` actions
    const request = yield take(PROFILE_REQUEST_SET)
    yield call(profileSet, request)
  }
}
