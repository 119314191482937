module.exports = {
  account: 'Kontobereich',
  changePassword: 'Passwort ändern',
  companyOverviewFallback: 'Woops, hier ist etwas schief gelaufen',
  contactEmail: 'E-Mail für Antwort',
  contactMessage: 'Nachricht',
  contactSubject: 'Betreff',
  email: 'E-Mail',
  forgotPassword: 'Passwort vergessen?',
  loadingPage: 'Seite wird geladen …',
  login: 'Anmelden',
  newPassword: 'Neues Passwort',
  oldPassword: 'Altes Passwort',
  password: 'Passwort',
  repeatPassword: 'Passwort wiederholen',
  reset: 'Zurücksetzen',
  resetPassword: 'Passwort zurücksetzen',
  resetPasswordMessage:
    'Geben Sie Ihre E-Mail Adresse ein. Wir senden Ihnen einen Link zum setzen eines neuen Passworts.',
  save: 'Speichern',
  companyName: 'Firmenname',
  streetAndNumber: 'Straße/ Hausnummer',
  zipCode: 'PLZ',
  city: 'Stadt',
  country: 'Land',
  legacyForm: 'Unternehmensform',
  foundingYear: 'Gründungsjahr',
  companySize: 'Mitarbeiteranzahl',
  phoneNumber: 'Telefonnummer',
  faxNumber: 'Faxnummer',
  websiteLink: 'Website Link',
  pressLink: 'Pressemitteilungen Link',
  blogLink: 'Blog Link',
  facebookLink: 'Facebook Link',
  linkedinLink: 'LinkedIn Link',
  xingLink: 'Xing Link',
  twitterLink: 'Twitter Link',
  githubLink: 'Github Link',
  categories: 'Kategorien',
  tags: 'Tags',
  aboutUs: 'Über uns',
  portfolio: 'Portfolio',
  logo: 'Logo',
  companyVideo: 'Video Link',
  select: 'Wählen ...',
  company: 'Firma',
  registerNow: 'Jetzt anmelden',
  submit: 'Absenden',
  userID: 'User-ID',
  addCompany: 'Firma hinzufügen',
  details: 'Details',
  headlineAboutPage: 'About',
  register: 'Jetzt registrieren',
  contact: 'Kontakt',
  deleteAccount: 'Konto löschen',
  changePackage: 'Paket ändern',
  editData: 'Bearbeiten Sie Ihren Eintrag',
  logout: 'Abmelden',
  passwordsDoNotMatch:
    'Das neue Passwort und die Passwort Bestätigung stimmen nicht überein!',
  incorrectEmail:
    'Ihre Anmeldedaten sind nicht korrekt. Versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück.',
  incorrectPassword:
    'Ihre Anmeldedaten sind nicht korrekt. Versuchen Sie es erneut oder setzen Sie Ihr Passwort zurück.',
  contactMessageWasSend: 'Ihre Anfrage wurde erfolgreich versendet',
  saveSuccess: 'Ihre Änderungen wurden erfolgreich gespeichert',
  incorrectPasswordChange: 'Ihr Passwort ist nicht korrekt.',
  mailSent:
    'Vielen Dank für Ihre Anfrage. Wir werden Ihr Anliegen so schnell wie möglich bearbeiten.',
  unknownError: 'Unbekannter Fehler',
  password_equals: 'Neues und altes Passwort müssen sich unterscheiden',
  registerSuccessfull: 'E-Mail wurde versendet',
  invalid_key:
    'Das Passwort konnte nicht gesetzt werden. Vermutlich ist dieser Link abgelaufen oder so Sie haben das Passwort schon erfolgreich gesetzt.',
  invalidcombo: 'Ihre E-Mail ist nicht bekannt.',
  password_set: 'Passwort wurde gesetzt.',
  password_changed: 'Passwort wurde geändert.',
  passwordReset:
    'Sie erhalten eine E-Mail mit einem Link. Über diesen können Sie ihr Passwort neu vergeben.',
  imageSizeExceeded: 'Sie können maximal 2MB große Bilder hochladen',
  maxAmoutOfImagesExceeded: 'Sie können maximal 7 Bilder hochladen',
  mediumEditorDesc:
    'Bitte fügen Sie Ihren Text ein und markieren Sie die Stelle, die formattiert werden soll. Die Editierungsoptionen erscheinen dann.',
}
