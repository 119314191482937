/*
 * Actions describe changes of state in your application
 */

// We import constants to name our actions' type
import {
  SET_AUTH,
  SET_API_RESULTS,
  GET_API_RESULTS_ERROR,
  CLEAR_API_RESULTS,
  SENDING_REQUEST,
  LOGIN_REQUEST,
  PASSWORD_SET,
  PASSWORD_RESET,
  PASSWORD_CHANGE,
  PRE_REGISTER_REQUEST,
  REGISTER_REQUEST,
  SEARCH_REQUEST,
  SUBSCRIBE_REQUEST,
  LOGOUT,
  PROFILE_REQUEST,
  PROFILE_REQUEST_SET,
  COMPANY_REQUEST,
  MAIL_REQUEST,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from './constants'

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export function setAuthState(newAuthState) {
  return { type: SET_AUTH, newAuthState }
}

/**
 * Sets the api results state of the application
 * @param  {object} API results, i.e. {company: ..., category: ...}
 */
export function setApiResults(results) {
  return { type: SET_API_RESULTS, results }
}

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function getApiResultsError(error) {
  return { type: GET_API_RESULTS_ERROR, error }
}

/**
 * Clears the api results state of the application back to default
 * which is false
 */
export function clearApiResults() {
  return { type: CLEAR_API_RESULTS }
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRequest(sending) {
  return { type: SENDING_REQUEST, sending }
}

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.username The username of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export function loginRequest(data) {
  // console.log('actions, loginRequest()', data)
  return { type: LOGIN_REQUEST, data }
}

/**
 * Tells the app we want to log out a user
 */
export function logout() {
  return { type: LOGOUT }
}

/**
 * Tells the app we want to register a user from the footer form
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.company  The company of the user to register
 */
export function preRegisterRequest(data) {
  return { type: PRE_REGISTER_REQUEST, data }
}

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.company  The company of the user to register
 */
export function registerRequest(data) {
  return { type: REGISTER_REQUEST, data }
}

/**
 * Tells the app we want to subscribe a user
 * @param  {object} data        The data we're sending for registration
 * @param  {string} data.userId The username of the user to register
 * @param  {string} data.token  The company of the user to register
 */
export function subscribeRequest(data) {
  // console.log('actions/index.js subscribeRequest()', data)
  return { type: SUBSCRIBE_REQUEST, data }
}

/**
 * Tells the app we want to set an users password the first time
 * @param  {object} data         The data we're sending for registration
 * @param  {string} data.userId  The key supplied via password set email
 * @param  {string} data.pass1   The first password
 * @param  {string} data.pass2   The second password. Should match the first password.
 * @param  {string} data.passold The users email
 */
export function passwordSet(data) {
  return { type: PASSWORD_SET, data }
}

/**
 * Tells the app we want to reset a user password
 * @param  {object} data         The data we're sending for password reset
 * @param  {string} data.login  The login (email) we send the reset information to
 */
export function passwordReset(data) {
  return { type: PASSWORD_RESET, data }
}

/**
 * Tells the app we want to change an users password
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.userId The users ID
 * @param  {string} data.passOld The old password
 * @param  {string} data.pass1 The first password
 * @param  {string} data.pass2 The second password. Should match the first password.
 */
export function passwordChange(data) {
  return { type: PASSWORD_CHANGE, data }
}

/**
 * Tells the app we want to get the companies data
 * @param  {object} data     The data we're sending for registration
 * @param  {string} optional: data.companyId The companies id, otherwise it it fetch data of all companies
 */
export function companyRequest(data) {
  return { type: COMPANY_REQUEST, data }
}

/**
 * Tells the app we want to get search results
 * @param  {string} searchString     The string we want to search for
 */
export function searchRequestGet(searchString) {
  return { type: SEARCH_REQUEST, searchString }
}

/**
 * Tells the app we want to get the users profile data
 * @param  {object} data     The data we're sending for fetching
 * @param  {string} data.companyId The users company id
 * @param  {string} data.userId    The users id
 * @param  {string} data.token     The users auth/JWT token
 */
export function profileRequestGet(data) {
  return { type: PROFILE_REQUEST, data }
}

/**
 * Tells the app we want to update the users profile data
 * @param  {object} data     The data we're sending for updating the profile
 */
export function profileRequestSet(data) {
  return { type: PROFILE_REQUEST_SET, data }
}

/**
 * Tells the app we want to send an email
 * @param  {object} data The data we're sending for sending the mail
 */
export function mailRequest(data) {
  // console.log('actions mailRequest()', data)
  return { type: MAIL_REQUEST, data }
}

/**
 * Tells the app we want to show the user a message
 * @param  {string} userMessage     The message we want to show in the user
 */
export function setMessage(userMessage) {
  return { type: USER_MESSAGE, userMessage }
}

/**
 * Set the `userMessage` state as empty
 */
export function clearMessage() {
  return { type: CLEAR_MESSAGE }
}
