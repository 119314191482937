import auth from '../../auth'
import { call, put, take } from 'redux-saga/effects'
import { navigate } from 'gatsby'

import {
  SENDING_REQUEST,
  CLEAR_API_RESULTS,
  REQUEST_ERROR,
  CLEAR_ERROR,
  USER_MESSAGE,
  CLEAR_MESSAGE,
  PRE_REGISTER_REQUEST,
  PRE_REGISTER_SET,
  REGISTER_REQUEST,
  REGISTER_SET,
} from '../../actions/constants'

/**
 * Effect to handle registration
 * @param  {string} username      The username of the user
 * @param  {string} company       The company of the user
 * @param  {object} options       Options
 */
export function* register({ username, company, package_type }) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })
  yield put({ type: CLEAR_ERROR })
  yield put({ type: CLEAR_MESSAGE })

  // We then try to register or log in the user, depending on the request
  try {
    let response

    // For registration we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    response = yield call(auth.register, username, company, package_type)

    // Throw Error if response has failed
    if (response instanceof Error) {
      // console.log('* register() try, throw error', response)
      throw response
    }

    // If we could register a user, we send the appropiate actions
    if (response) {
      yield put({ type: REGISTER_SET, newRegisterState: true })
      yield put({
        type: USER_MESSAGE,
        userMessage: {
          code: 'register_success',
          message: 'Erfolgreich registriert.',
        },
      })
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: SENDING_REQUEST, sending: false, lastCall: 'register' })
  }
}

/**
 * preRegister saga
 * Save data when user entered the small register form to pass it to the register page
 */
export function* preRegisterFlow() {
  while (true) {
    // We always listen to `PRE_REGISTER_REQUEST` actions
    const request = yield take(PRE_REGISTER_REQUEST)
    const data = request.data

    if (data) {
      yield put({ type: PRE_REGISTER_SET, toRegisterData: request.data })
      navigate('/register') // Go to register page
    }
  }
}

/**
 * Register saga
 * Very similar to log in saga!
 */
export function* registerFlow() {
  while (true) {
    // We always listen to `REGISTER_REQUEST` actions
    const request = yield take(REGISTER_REQUEST)
    const { username, company, package_type } = request.data

    yield call(register, {
      username,
      company,
      package_type,
    })
  }
}
