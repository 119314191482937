import { createMuiTheme } from '@material-ui/core/styles'
import {
  mainColor,
  secondaryColor,
  white,
  black,
  headerContainer,
  darkGrey,
  lightGrey,
  boxGrey,
  lineGrey,
  introColor,
  introCategories,
} from '../../site-config'

import 'typeface-roboto'

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondaryColor,
    },
    highlightButton: {
      main: mainColor,
      contrastText: white,
    },
    headerContainer: {
      main: headerContainer,
      contrastText: white,
    },
    error: {
      main: mainColor,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
    caption: {
      color: darkGrey,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: black,
      },
    },
    MuiTab: {
      root: {
        color: lightGrey,
        '&$selected': {
          color: black,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '13px 20px',
        textTransform: 'uppercase',
        minHeight: '36px',
      },
    },
    MuiGridListTile: {
      root: {
        '@media (max-width:599px)': {
          width: '100% !important',
          height: 'auto !important',
        },
      },
      imgFullHeight: {
        '@media (max-width:599px)': {
          width: '100%',
          left: 0,
          position: 'inherit',
          transform: 'none',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: black,
      },
    },
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: 0,
        '@media (min-width:600px)': {
          top: 0,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        minWidth: 'auto',
        flexGrow: 'initial',
        '@media (min-width:600px)': {
          minWidth: 'auto',
        },
      },
    },
  },
  customStyles: {
    extraColors: {
      darkGrey: darkGrey,
      lightGrey: lightGrey,
      boxGrey: boxGrey,
      lineGrey: lineGrey,
      white: white,
      black: black,
      introColor: introColor,
      introCategories: introCategories,
    },
    mainContent: {
      maxWidth: 1350,
      width: '100%',
    },
    profileSubmit: {
      marginTop: 16, //check how to use pxToRem()
      backgroundColor: mainColor,
      minWidth: 257,
      color: white,
    },
    noUnderline: {
      textDecoration: 'none',
    },
  },
})

export default theme
