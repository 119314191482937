import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import ASnackbar from './a-snackbar.js'

class ASnackbarStore extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { userMessage } = this.props
    return userMessage ? <ASnackbar messageCode={userMessage.code} /> : ''
  }
}

ASnackbarStore.propTypes = {
  userMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

const mapStateToProps = state => {
  return {
    userMessage: state.userMessage,
  }
}

export default connect(mapStateToProps)(ASnackbarStore)
