module.exports = [{
      plugin: require('../plugins/gatsby-plugin-dm-google-tagmanager-user-centrics/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WF37SNZ","includeInDevelopment":false,"usercentricsID":"wQVzuUl2a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
