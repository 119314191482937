import { put } from 'redux-saga/effects'
import { USER_MESSAGE } from '../../actions/constants'

/**
 * User Message Saga
 * Show the user a message
 */
export function* userMessageFlow(message) {
  if (message) {
    yield put({ type: USER_MESSAGE, userMessage: message })
  }
}
