import { call, put, take } from 'redux-saga/effects'
import apiCalls from '../apiCalls'

import {
  SENDING_REQUEST,
  SET_API_RESULTS,
  CLEAR_API_RESULTS,
  REQUEST_ERROR,
  CLEAR_ERROR,
  USER_MESSAGE,
  CLEAR_MESSAGE,
  MAIL_REQUEST,
} from '../../actions/constants'

/**
 * Effect to send profile informations via API
 * @param  {Object} data The date we're updating
 */
export function* mailRequest(request) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })

  try {
    yield put({ type: CLEAR_ERROR })
    yield put({ type: CLEAR_MESSAGE })
    const response = yield call(apiCalls.mailRequest, request.data)

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }

    if (response.data == true) {
      yield put({
        type: SET_API_RESULTS,
        results: true,
      })
    }
    yield put({
      type: USER_MESSAGE,
      userMessage: {
        code: 'mail_sent',
        message: 'E-Mail wurde verschickt.',
      },
    })
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })
    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({
      type: SENDING_REQUEST,
      sending: false,
      lastCall: request.data.type ? request.data.type : 'mailRequest',
    })
  }
}

/**
 * Profile Edit saga
 */
export function* mailRequestFlow() {
  while (true) {
    // We always listen to `MAIL_REQUEST` actions
    const request = yield take(MAIL_REQUEST)
    yield call(mailRequest, request)
  }
}
