// Color Settings
// in case of 'com' it is red with value #C1001F
export const mainColor = '#0067b0'
export const secondaryColor = '#E6E6E6'
export const headerContainer = '#fafafa'
export const darkGrey = '#4A4A4A'
export const lightGrey = '#979797'
export const boxGrey = '#F7F7F7'
export const lineGrey = '#D8D8D8'
export const white = '#fff'
export const black = '#000'
export const introColor = '#000'
export const introCategories = '#000'

export const globalNaming = {
  entryType: 'Anbieter',
  entryTypeAlternative: 'Bezugsquellen',
  searchPlaceholder: 'Suchbegriff eingeben, z.B. Distributor',
}

export const metaData = {
  siteTitle: `${globalNaming.entryType}verzeichnis Telecom Handel`,
  siteDescription:
    'Veröffentlichen Sie Ihren Firmeneintrag inklusive aller Kontaktinformationen, verweise auf eure Sozialen Kanäle und präsentieren Sie sich mit einer hochauflösenden Headergrafik.',
  siteKeywords:
    'Dienstleisterverzeichnis, Anbieterverzeichnis, Firmeneintrag, Firmenverzeichnis, Unternehmen, präsentieren, veröffentlichen',
}

export const appInfo = {
  introTitle: `Das ${globalNaming.entryType}verzeichnis für den ITK-Handel`,
}

export const companyInfo = {
  shortName: 'TH',
  longName: 'Telecom Handel',
  phone: '+49 731 88005-8125',
  email: 'service@telecom-handel.de',
}

export const contactInfo = {
  contactPerson: 'Klaus Ahlering',
  contactPersonPhone: '+49 731 88005-8125',
  contactPersonEmail: 'klaus.ahlering@telecom-handel.de',
  supportEmail: 'service@telecom-handel.de',
  companyName: 'Ebner Media Group GmbH & Co. KG, Büro München',
  companyAdress: 'Bayerstraße 16a, 80335 München',
  companyPhone: 'Tel: +49 731 88005-8000',
}

export const aboutPage = {
  numbers: {
    1: '250 Tsd.',
    2: '15 Tsd.',
    3: '12 Tsd.',
    4: '7 Tsd.',
  },
  keyword: 'Telekommunikations',
  editions: '30.000',
}

export const logo = {
  width: 196,
  height: 40,
}

export const packagesText = {
  teaser: `Profitieren Sie von der Reichweite der ${companyInfo.longName}
  und generieren Sie neue Kundenpotenziale. Melden Sie sich noch
  heute an.`,
}

export const packages = [
  {
    packagename: 'business',
    price: '79,- €/Monat',
    listItems: [
      { item: 'Firmenname inkl. "Über uns"' },
      { item: 'Ihr Kontakt' },
      { item: 'Social-Media-Profile' },
      { item: 'Ihr Portfolio' },
      { item: 'Ihre Referenzen' },
      { item: 'Logo, Bilder, Video' },
      { item: 'Telecom Handel Digital-Abo' },
    ],
  },
  {
    packagename: 'premium',
    price: '199,- €/Monat',
    listItems: [
      { item: 'Firmenname inkl. "Über uns"' },
      { item: 'Ihr Kontakt' },
      { item: 'Social-Media-Profile' },
      { item: 'Ihr Portfolio' },
      { item: 'Ihre Referenzen' },
      { item: 'Logo, Bilder, Video' },
      { item: 'Telecom Handel Digital-Abo' },
      { item: 'Telecom Handel Print-Abo' },
      { item: 'Ihr Firmeneintrag in der gedruckten Zeitung' },
      { item: 'Ihr Firmeneintrag im Telecom Handel Jahrbuch' },
      { item: 'Persönlicher Support' },
    ],
  },
]

export const sideNavEntries = [
  {
    entryNo: 1,
    label: 'Ihr Eintrag',
    target: '/account/',
  },
  {
    entryNo: 2,
    label: 'Passwort ändern',
    target: '/account/change-password',
  },
  {
    entryNo: 3,
    label: 'Kontakt',
    target: '/account/contact',
  },
  {
    entryNo: 4,
    label: 'Paket ändern',
    target: '/account/change-package',
  },
  {
    entryNo: 5,
    label: 'Konto löschen',
    target: '/account/delete-account',
  },
]

export const mainNavEntries = [
  {
    label: 'Firmen finden',
    target: '/',
  },
]

export const secondNavEntries = [
  {
    label: `Warum ${globalNaming.entryType}verzeichnis`,
    target: '/about',
  },
  {
    label: 'Pakete',
    target: '/packages',
  },
]

export const buttonNavEntries = [
  {
    label: 'ANMELDEN',
    target: '/login',
  },
  {
    label: 'FIRMA HINZUFÜGEN',
    target: '/register',
  },
]

export const footNavEntries = [
  {
    label: 'Impressum',
    target: 'https://www.telecom-handel.de/kontakt-impressum-756967.html',
  },
  {
    label: 'Kontakt',
    target: '/contact',
  },
  {
    label: 'AGB',
    target: 'https://www.ebnermedia.de/ebvm/agb',
  },
  {
    label: 'Datenschutz',
    target: 'https://www.ebnermedia.de/ebvm/dse',
  },
  {
    label: 'Nutzungsbedingungen',
    target: 'https://www.ebnermedia.de/ebvm/nzb',
  },
]
