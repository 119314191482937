import React from 'react'
import { intlIdToString } from '../components/00-utils/u-translation'
import { metaData, contactInfo } from '../../site-config'

export function getMessageAndVariant(code) {
  let message, variant
  switch (code) {
    case '[jwt_auth] invalid_email':
      message = (
        <React.Fragment>{intlIdToString('incorrectEmail')}</React.Fragment>
      )
      variant = 'error'
      break
    case '[jwt_auth] incorrect_password':
      message = (
        <React.Fragment>{intlIdToString('incorrectPassword')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'passwords_do_not_match':
      message = (
        <React.Fragment>{intlIdToString('passwordsDoNotMatch')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'password_equals':
      message = (
        <React.Fragment>{intlIdToString('password_equals')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'password_set':
      message = (
        <React.Fragment>{intlIdToString('password_set')}</React.Fragment>
      )
      variant = 'success'
      break
    case 'password_changed':
      message = (
        <React.Fragment>{intlIdToString('password_changed')}</React.Fragment>
      )
      variant = 'success'
      break
    case 'password_reset':
      message = (
        <React.Fragment>{intlIdToString('passwordReset')}</React.Fragment>
      )
      variant = 'success'
      break
    case 'invalid_credentials':
      message = (
        <React.Fragment>
          {intlIdToString('incorrectPasswordChange')}
        </React.Fragment>
      )
      variant = 'error'
      break

    case 'invalidcombo':
      message = (
        <React.Fragment>{intlIdToString('invalidcombo')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'empty_username':
      message = (
        <React.Fragment>{intlIdToString('incorrectEmail')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'invalid_key':
      message = <React.Fragment>{intlIdToString('invalid_key')}</React.Fragment>
      variant = 'info'
      break

    case 'rest_forbidden':
      message = <div>Die Anmeldung am System ist fehlgeschlagen</div>
      variant = 'error'
      break
    case 'username_or_email_exists':
      message = (
        <div>
          Sie sind bereits bei uns registriert.
          <br />
          Bitte wenden Sie sich an unseren Support unter{' '}
          <a href="mailto:datacenter@ebnermedia.de" rel="noopener noreferrer">
            datacenter@ebnermedia.de
          </a>
          <br />
          Wir unterstützen Sie gerne beim weiteren Registrierungsprozess.
          <br />
        </div>
      )
      variant = 'info'
      break
    case 'exclude_private_emails':
      message = (
        <div>
          Danke für Ihr Interesse an unserem {metaData.siteTitle}. Leider können
          wir aus datenschutzrechtlichen Gründen keine Registrierungen mit
          privaten E-Mail-Adressen vornehmen. Verwenden Sie bitte eine Adresse
          aus Ihrer Unternehmensdomain. Für Rückfragen wenden Sie sich bitte an{' '}
          <a
            href={`mailto:${contactInfo.contactPersonEmail}`}
            rel="noopener noreferrer"
          >
            {contactInfo.contactPerson}
          </a>
          .<br />
        </div>
      )
      variant = 'error'
      break
    case 'image_size_exceeded':
      message = (
        <React.Fragment>{intlIdToString('imageSizeExceeded')}</React.Fragment>
      )
      variant = 'error'
      break
    case 'max_amout_of_images_exceeded':
      message = (
        <React.Fragment>
          {intlIdToString('maxAmoutOfImagesExceeded')}
        </React.Fragment>
      )
      variant = 'error'
      break
    case 'example_code':
      message = <React.Fragment>Example Message</React.Fragment>
      variant = 'warning'
      break
    case 'save_success':
      message = <React.Fragment>{intlIdToString('saveSuccess')}</React.Fragment>
      variant = 'success'
      break
    case 'register_success':
      message = (
        <React.Fragment>{intlIdToString('registerSuccessfull')}</React.Fragment>
      )
      variant = 'success'
      break
    case 'mail_sent':
      message = <React.Fragment>{intlIdToString('mailSent')}</React.Fragment>
      variant = 'success'
      break
    case 'registerSuccessfull':
      message = (
        <React.Fragment>{intlIdToString('registerSuccessfull')}</React.Fragment>
      )
      variant = 'success'
      break
    default:
      message = (
        <React.Fragment>{intlIdToString('unknownError')}</React.Fragment>
      )
      variant = 'error'
  }
  return { message: message, variant: variant }
}
