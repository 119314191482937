/*
 * The reducer takes care of state changes in our app through actions
 */

import {
  SET_AUTH,
  SET_API_RESULTS,
  COMPANY_OVERVIEW_FILTER,
  CLEAR_API_RESULTS,
  SENDING_REQUEST,
  PROFILE_UPDATE,
  PRE_REGISTER_SET,
  REGISTER_SET,
  SEARCH_SET,
  SEARCH_SET_RESULT,
  SUBSCRIBE_SET,
  REQUEST_ERROR,
  CLEAR_ERROR,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from '../actions/constants'
import auth from '../auth'

// The initial application state
let initialState = {
  apiResults: false,
  companyOverviewFilter: {
    category: false,
    tags: [],
    city: false,
    country: false,
    search: false,
    page: false,
  },
  lastCall: '',
  login: auth.loggedIn(),
  register: {
    registered: auth.loggedIn() || false,
  },
  error: false,
  currentlySending: false,
  search: {
    string: false,
    result: false,
  },
}

// Takes care of changing the application state
function reducer(state = initialState, action) {
  // console.log(action.type, state, action)
  switch (action.type) {
    case CLEAR_API_RESULTS:
      return {
        ...state,
        apiResults: false,
      }
    case SET_API_RESULTS:
      return {
        ...state,
        apiResults: action.results,
      }
    case COMPANY_OVERVIEW_FILTER:
      return {
        ...state,
        companyOverviewFilter: action.companyOverviewFilter,
      }
    case SET_AUTH:
      return {
        ...state,
        login: action.newAuthState,
      }
    case SENDING_REQUEST:
      return {
        ...state,
        currentlySending: action.sending,
        lastCall:
          typeof action.lastCall == 'string' ? action.lastCall : state.lastCall,
      }
    case PROFILE_UPDATE:
      Object.entries(action.updatedFields).forEach(fieldgroup => {
        if (fieldgroup[0] == 'company_tags') {
          state.apiResults.company[fieldgroup[0]] = fieldgroup[1]
        } else if (fieldgroup[0] == 'company_categories') {
          state.apiResults.company[fieldgroup[0]] = fieldgroup[1]
        } else if (fieldgroup[0] == 'company_links') {
          state.apiResults.company[fieldgroup[0]] = state.apiResults.company[
            fieldgroup[0]
          ].map(item => {
            fieldgroup[1].forEach(element => {
              if (element.slug == item.slug) {
                item.url = element.url
              }
            })
            return item
          })
        } else {
          state.apiResults.company[fieldgroup[0]] = Object.assign(
            state.apiResults.company[fieldgroup[0]],
            fieldgroup[1]
          )
        }
      })

      return {
        ...state,
      }
    case PRE_REGISTER_SET:
      return {
        ...state,
        register: {
          ...state.register,
          registerData: action.toRegisterData,
        },
      }
    case REGISTER_SET:
      return {
        ...state,
        register: {
          ...state.login,
          registered: action.newRegisterState,
        },
      }
    case SUBSCRIBE_SET:
      return {
        ...state,
        login: {
          ...state.login,
          status: {
            ...state.login.status,
            registration: action.newDLVZStatus,
          },
        },
      }
    case SEARCH_SET:
      return {
        ...state,
        search: {
          ...state.search,
          string: action.searchString,
        },
      }
    case SEARCH_SET_RESULT:
      return {
        ...state,
        search: {
          ...state.search,
          result: action.searchResultIds,
        },
      }
    case REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: false,
      }
    case USER_MESSAGE:
      return {
        ...state,
        userMessage: action.userMessage,
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        userMessage: '',
      }
    default:
      return state
  }
}

export default reducer
