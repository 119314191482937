// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place

// import { hashSync } from 'bcryptjs'
// import genSalt from '../auth/salt'

import { fork } from 'redux-saga/effects'

import { mailRequestFlow } from './mail'
import { preRegisterFlow, registerFlow } from './register'
import { searchFlowDebounced } from './search'
import { profileGetFlow, profileSetFlow } from './profile'
import { subscribeFlow } from './subscribe'
import { companyGetFlow } from './company'
import { passwordFlow, passwordResetFlow, passwordChangeFlow } from './password'
import { logoutFlow } from './logout'
import { loginFlow } from './login'
import { userMessageFlow } from './userMessage'

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* root() {
  yield fork(loginFlow)
  yield fork(logoutFlow)
  yield fork(preRegisterFlow)
  yield fork(registerFlow)
  yield fork(passwordFlow)
  yield fork(passwordResetFlow)
  yield fork(passwordChangeFlow)

  yield fork(subscribeFlow)

  yield fork(profileGetFlow)
  yield fork(profileSetFlow)

  yield fork(companyGetFlow)

  yield fork(searchFlowDebounced)
  yield fork(mailRequestFlow)
  yield fork(userMessageFlow)
}
