import { call, put, take } from 'redux-saga/effects'
import apiCalls from '../apiCalls'

import {
  SENDING_REQUEST,
  SET_API_RESULTS,
  CLEAR_API_RESULTS,
  REQUEST_ERROR,
  CLEAR_ERROR,
  PASSWORD_SET,
  PASSWORD_RESET,
  PASSWORD_CHANGE,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from '../../actions/constants'

/**
 * Effect to set password after registration
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.key The key supplied via password set email
 * @param  {string} data.pass1 The first password
 * @param  {string} data.pass2 The second password. Should match the first password.
 * @param  {string} data.username The users email
 */
export function* passwordSet({ key, pass1, pass2, username }) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })
  yield put({ type: CLEAR_ERROR })
  yield put({ type: CLEAR_MESSAGE })

  // We then try to register or log in the user, depending on the request
  try {
    const response = yield call(apiCalls.setPassword, {
      key,
      pass1,
      pass2,
      username,
    })

    if (typeof response == 'object') {
      yield put({
        type: SET_API_RESULTS,
        results: response,
      })
      yield put({
        type: USER_MESSAGE,
        userMessage: {
          code: 'password_set',
          message: 'Passwort wurde gesetzt.',
        },
      })
    }

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })
    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })

    yield put({
      type: SET_API_RESULTS,
      results: false,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({
      type: SENDING_REQUEST,
      sending: false,
      lastCall: 'passwordSet',
    })
  }
}

/**
 * Effect to reset password
 * @param  {object} data          The data we're sending for password reset
 * @param  {string} data.login The login (email) needed to reset password
 */
export function* passwordReset({ login }) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })
  yield put({ type: CLEAR_ERROR })
  yield put({ type: CLEAR_MESSAGE })

  // We then try to register or log in the user, depending on the request
  try {
    const response = yield call(apiCalls.resetPassword, {
      login,
    })
    if (typeof response == 'object') {
      yield put({
        type: SET_API_RESULTS,
        results: response,
      })
      yield put({
        type: USER_MESSAGE,
        userMessage: {
          code: 'password_reset',
          message:
            'Sie erhalten eine E-Mail mit einem Link. Über diesen können Sie ihr Passwort neu vergeben.',
        },
      })
    }
    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })
    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
    yield put({
      type: SET_API_RESULTS,
      results: false,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({
      type: SENDING_REQUEST,
      sending: false,
      lastCall: 'passwordReset',
    })
  }
}

/**
 * Effect to send update an users password/profile
 * @param  {Object} data The date we're updating
 */
export function* passwordChange(request) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })

  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })

  // We then try to register or log in the user, depending on the request
  try {
    yield put({ type: CLEAR_ERROR })
    yield put({ type: CLEAR_MESSAGE })
    const response = yield call(apiCalls.changePassword, request.data)

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }

    if (typeof response == 'object') {
      yield put({
        type: SET_API_RESULTS,
        results: response,
      })
      // TODO: return new token
      yield put({
        type: USER_MESSAGE,
        userMessage: {
          code: 'password_changed',
          message: 'Passwort wurde geändert.',
        },
      })
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })
    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({
      type: SENDING_REQUEST,
      sending: false,
      lastCall: 'passwordChange',
    })
  }
}

/**
 * Password saga
 */
export function* passwordFlow() {
  while (true) {
    // We always listen to `PASSWORD_SET` actions
    const request = yield take(PASSWORD_SET)
    yield call(passwordSet, request.data)
  }
}

/**
 * Password Reset saga
 */
export function* passwordResetFlow() {
  while (true) {
    // We always listen to `PASSWORD_RESET` actions
    const request = yield take(PASSWORD_RESET)
    yield call(passwordReset, request.data)
  }
}

/**
 * Profile Edit saga
 */
export function* passwordChangeFlow() {
  while (true) {
    // We always listen to `PASSWORD_CHANGE` actions
    const request = yield take(PASSWORD_CHANGE)
    yield call(passwordChange, request)
  }
}
