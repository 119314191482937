import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { logout } from '../../actions'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import withStyles from '@material-ui/styles/withStyles'
import { intlIdToString } from '../00-utils/u-translation'

import AButton from '../01-atoms/a-button'
import ALogo from '../01-atoms/a-logo'
import MMenu from '../02-molecules/m-menu.js'
import MMenuDialog from '../02-molecules/m-menu-dialog.js'

import { mainNavEntries, secondNavEntries, logo } from '../../../site-config'
import Logo from '../../assets/svgs/logo.svg'

const styles = theme => ({
  logo: {
    marginTop: theme.typography.pxToRem(5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.typography.pxToRem(35),
      marginTop: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.typography.pxToRem(45),
    },
  },
  svgLogo: {
    maxHeight: 48,
    [theme.breakpoints.up('md')]: {
      maxHeight: 55,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.headerContainer.main,
    position: 'relative',
    alignItems: 'center',
  },
  toolbarContainer: {
    maxWidth: 1350,
    width: '100%',
    paddingLeft: theme.typography.pxToRem(40),
    paddingRight: theme.typography.pxToRem(40),
  },
  buttonHeader: {
    backgroundColor: theme.customStyles.extraColors.darkGrey,
    color: theme.palette.getContrastText(
      theme.customStyles.extraColors.darkGrey
    ),
    [theme.breakpoints.up('md')]: {
      marginLeft: 10,
    },
  },
  link: {
    padding: '25px 0',
    marginRight: theme.typography.pxToRem(25),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    borderRadius: 0,
    fontWeight: '600',
    '&:hover': {
      boxShadow: 'inset 0 -3px 0 0',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.typography.pxToRem(40),
    },
  },
  menuList: {
    marginLeft: 'auto',
  },
  activeLink: {
    boxShadow: 'inset 0 -3px 0 0',
  },
})

class OHeader extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    //e.preventDefault()
    this.props.logout()
  }

  render() {
    const { classes, data } = this.props
    const actionButtons =
      typeof data.login == 'object'
        ? [
            <AButton
              key="1"
              className={classes.buttonHeader}
              variant="contained"
              to="/account"
            >
              {intlIdToString('account')}
            </AButton>,
            <AButton
              key="2"
              className={classes.buttonHeader}
              variant="contained"
              onClick={this.handleClick}
            >
              {intlIdToString('logout')}
            </AButton>,
          ]
        : [
            <AButton key="0" color="primary" variant="contained" to="/register">
              {intlIdToString('addCompany')}
            </AButton>,
            <AButton
              key="1"
              className={classes.buttonHeader}
              color="secondary"
              variant="contained"
              to="/login"
              // loading={data.currentlySending}
            >
              {intlIdToString('login')}
            </AButton>,
          ]

    return (
      <AppBar elevation={2} position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbarContainer}>
          <ALogo className={classes.logo} to="/">
            <Logo
              width={logo.width}
              height={logo.height}
              className={classes.svgLogo}
            />
          </ALogo>
          <Hidden smDown>
            <MMenu
              inline
              entries={mainNavEntries}
              className={classes.link}
              activeclassname={classes.activeLink}
            />
            <MMenu
              inline
              entries={secondNavEntries}
              classes={{
                link: classes.link,
                menuList: classes.menuList,
              }}
              activeclassname={classes.activeLink}
            />
            {actionButtons}
          </Hidden>
          <Hidden mdUp>
            <MMenuDialog
              entries={mainNavEntries.concat(secondNavEntries)}
              buttonEntries={actionButtons}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }
}

OHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    data: {
      login: state.login,
    },
  }
}

const mapDispatchToProps = dispatch => {
  // console.log(loginRequest({ username: 'abc', password: 'def' }))
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OHeader))
