import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import withStyles from '@material-ui/styles/withStyles'

import MMenu from '../02-molecules/m-menu.js'

import { footNavEntries } from '../../../site-config'

const styles = theme => ({
  footerAppBar: {
    position: 'relative',
    alignItems: 'center',
  },
  footerToolbarContainer: {
    maxWidth: 1350,
    width: '100%',
  },
  link: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    padding: '10px 25px',
    [theme.breakpoints.up('sm')]: {
      padding: '25px',
    },
  },
})
class OFooter extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <AppBar
        component="footer"
        position="static"
        className={classes.footerAppBar}
        elevation={2}
        color="default"
      >
        <Toolbar className={classes.footerToolbarContainer}>
          <MMenu inline entries={footNavEntries} className={classes.link} />
        </Toolbar>
      </AppBar>
    )
  }
}

OFooter.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OFooter)
