import { call, put, take } from 'redux-saga/effects'
import auth from '../../auth'
import { navigate } from 'gatsby'

import {
  SENDING_REQUEST,
  CLEAR_API_RESULTS,
  SET_AUTH,
  LOGOUT,
  REQUEST_ERROR,
  USER_MESSAGE,
} from '../../actions/constants'

/**
 * Effect to handle logging out
 */
export function* logout() {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We tell Redux we're in the middle of a request
  yield put({ type: SENDING_REQUEST, sending: true })

  // Similar to above, we try to log out by calling the `logout` function in the
  // `auth` module. If we get an error, we send an appropiate action. If we don't,
  // we return the response.
  try {
    yield call(auth.logout)
    yield put({ type: SENDING_REQUEST, sending: false })
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })
  }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export function* logoutFlow() {
  while (true) {
    yield take(LOGOUT)
    yield put({ type: SET_AUTH, newAuthState: false })
    yield call(logout)
    navigate('/')
  }
}
