/*
 * These are the variables that determine what our central data store (`../reducers/index.js`)
 * changes in our state.
 */

export const SET_AUTH = 'SET_AUTH'
export const SET_API_RESULTS = 'SET_API_RESULTS'
export const GET_API_RESULTS_ERROR = 'GET_API_RESULTS_ERROR'
export const CLEAR_API_RESULTS = 'CLEAR_API_RESULTS'
export const SENDING_REQUEST = 'SENDING_REQUEST'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const PRE_REGISTER_REQUEST = 'PRE_REGISTER_REQUEST'
export const PRE_REGISTER_SET = 'PRE_REGISTER_SET'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SET = 'REGISTER_SET'
export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SET = 'SEARCH_SET'
export const SEARCH_SET_RESULT = 'SEARCH_SET_RESULT'
export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST'
export const SUBSCRIBE_SET = 'SUBSCRIBE_SET'
export const LOGOUT = 'LOGOUT'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_REQUEST_SET = 'PROFILE_REQUEST_SET'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const COMPANY_REQUEST = 'COMPANY_REQUEST'
export const COMPANY_OVERVIEW_FILTER = 'COMPANY_OVERVIEW_FILTER'
export const PASSWORD_SET = 'PASSWORD_SET'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE'
export const MAIL_REQUEST = 'MAIL_REQUEST'
export const USER_MESSAGE = 'USER_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'
