import { delay } from 'redux-saga/effects'
import { all, call, put, take } from 'redux-saga/effects'
import apiCalls from '../apiCalls'

import {
  SENDING_REQUEST,
  SEARCH_SET_RESULT,
  SET_API_RESULTS,
  CLEAR_ERROR,
  COMPANY_REQUEST,
  COMPANY_OVERVIEW_FILTER,
} from '../../actions/constants'

/**
 * Effect to fetch company infos
 * @param  {string} companyId The companies id
 */
export function* companyGet({ companyId, query, filter }) {
  // clear the api results to be sure
  // yield put({ type: CLEAR_API_RESULTS }) commented to prevent loading wheel during filtering
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })

  //limit api requests to 5 retries
  for (let i = 0; i < 5; i++) {
    try {
      yield put({ type: CLEAR_ERROR })
      if (query == 'all') {
        const [categories, company, tags, locations] = yield all([
          call(apiCalls.getCategory, false, filter),
          call(apiCalls.getCompany, false, filter),
          call(apiCalls.getTag, false, filter),
          call(apiCalls.getLocations, filter),
        ])

        return {
          category: categories,
          company: company,
          tag: tags,
          locations: locations,
        }
      } else if (query == 'single') {
        const [categories, company, tags] = yield all([
          call(apiCalls.getCategory),
          call(apiCalls.getCompany, companyId),
          call(apiCalls.getTag),
        ])

        return {
          category: categories,
          company: company,
          tag: tags,
        }
      }
    } catch (error) {
      if (i < 4) {
        yield delay(2000)
      }
    } finally {
      if (typeof filter == 'object') {
        yield put({
          type: COMPANY_OVERVIEW_FILTER,
          companyOverviewFilter: filter,
        })
        if (typeof filter.search == 'string') {
          yield put({ type: SEARCH_SET_RESULT, searchResultIds: true })
        }
      }

      // When done, we tell Redux we're not in the middle of a request any more
      yield put({
        type: SENDING_REQUEST,
        sending: false,
        lastCall: 'companyGet' + query[0].toUpperCase() + query.substring(1),
      })
    }
  }
}

/**
 * Company saga
 */
export function* companyGetFlow() {
  while (true) {
    const { data } = yield take(COMPANY_REQUEST)
    const response = yield call(companyGet, data)

    yield put({
      type: SET_API_RESULTS,
      results: response,
    })
  }
}
