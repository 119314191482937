import { call, put, take } from 'redux-saga/effects'
import auth from '../../auth'

import {
  SENDING_REQUEST,
  SUBSCRIBE_REQUEST,
  SUBSCRIBE_SET,
  CLEAR_API_RESULTS,
  REQUEST_ERROR,
  CLEAR_ERROR,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from '../../actions/constants'

/**
 * Effect to handle subscription
 * @param  {string} userId The username of the user to register
 * @param  {string} token  The company of the user to register
 */
export function* subscribe({ userId, token }) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })
  yield put({ type: CLEAR_ERROR })
  yield put({ type: CLEAR_MESSAGE })

  // We then try to register or log in the user, depending on the request
  try {
    // For subscription we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    const response = yield call(auth.subscribe, userId, token)

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    }

    // If we could register a user, we send the appropiate actions
    if (response) {
      yield put({
        type: SUBSCRIBE_SET,
        newDLVZStatus: 'pending',
      }) // User is now registered
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })

    return false
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: SENDING_REQUEST, sending: false, lastCall: 'subscribe' })
  }
}

/**
 * Subscribe saga
 * User is already known in Datacenter, so we can subscribe this user to DLVZ.
 */
export function* subscribeFlow() {
  while (true) {
    // We always listen to `SUBSCRIBE_REQUEST` actions
    const request = yield take(SUBSCRIBE_REQUEST)
    const { userId, token } = request.data
    yield call(subscribe, {
      userId,
      token,
    })
  }
}
