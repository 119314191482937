import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ALink from '../01-atoms/a-link.js'

/** ALogo */
class ALogo extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { to, ...props } = this.props

    if (typeof this.props.to == 'string') {
      var delegateProps = Object.assign({}, this.props) // we have to copy the props object to be able to delete the to property

      // delete props we don't want to give to <img>
      delete delegateProps.to
      delete delegateProps.children

      return (
        <ALink to={to} {...props} noButton>
          {this.props.children ? (
            this.props.children
          ) : (
            <img {...delegateProps} />
          )}
        </ALink>
      )
    } else {
      return <img {...this.props} />
    }
  }
}

ALogo.propTypes = {
  /** Target of this Logo. If property 'to' is set, the logo will be wrapper in ALink. If it's not set, it will just return an <img> tag. */
  to: PropTypes.string,
  /** The source of the logo. Will be delegated to the <img> Markup. */
  src: PropTypes.string,
  /** use children node if your logo is no simple image */
  children: PropTypes.node,
}

export default ALogo
