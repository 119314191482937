import { call, put, race, take } from 'redux-saga/effects'
import auth from '../../auth'
import { navigate } from 'gatsby'

import {
  SENDING_REQUEST,
  LOGIN_REQUEST,
  CLEAR_API_RESULTS,
  SET_AUTH,
  LOGOUT,
  REQUEST_ERROR,
  CLEAR_ERROR,
  USER_MESSAGE,
  CLEAR_MESSAGE,
} from '../../actions/constants'

/**
 * Effect to handle authorization
 * @param  {string} username               The username of the user
 * @param  {string} password               The password of the user
 */
export function* authorize({ username, password }) {
  // clear the api results to be sure
  yield put({ type: CLEAR_API_RESULTS })
  // We send an action that tells Redux we're sending a request
  yield put({ type: SENDING_REQUEST, sending: true })
  yield put({ type: CLEAR_ERROR })
  yield put({ type: CLEAR_MESSAGE })

  // We then try to register or log in the user, depending on the request
  try {
    // const salt = genSalt(username)
    // const hash = hashSync(password, salt)

    // For log in we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    const response = yield call(auth.login, username, password)

    // Throw Error if response has failed
    if (response instanceof Error) {
      throw response
    } else {
      const userInfo = yield call(
        auth.getUserInfos,
        response.user_id,
        response.token
      )

      // Throw Error if response has failed
      if (userInfo instanceof Error) {
        throw userInfo
      } else {
        return userInfo
      }
    }
  } catch (error) {
    yield put({
      type: USER_MESSAGE,
      userMessage:
        typeof error.response.data.message == 'string' &&
        typeof error.response.data.code == 'string'
          ? error.response.data
          : {
              code: 'unknown_error',
              message:
                'Unbekannter Fehler. Bitte probieren Sie es später erneut.',
            },
    })

    // If we get an error we send Redux the appropiate action and return
    yield put({
      type: REQUEST_ERROR,
      error: true,
    })

    return false
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: SENDING_REQUEST, sending: false, lastCall: 'authorize' })
  }
}

/**
 * Log in saga
 */
export function* loginFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(LOGIN_REQUEST)
    const { username, password } = request.data

    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const winner = yield race({
      auth: call(authorize, { username, password }),
      logout: take(LOGOUT),
    })

    // If `authorize` was the winner...
    if (winner.auth && !(winner.auth instanceof Error)) {
      // ...we send Redux appropiate actions
      yield put({ type: SET_AUTH, newAuthState: winner.auth }) // User is logged in (authorized)
      navigate('/account') // Go to dashboard page
    }
  }
}
