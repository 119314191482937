import { delay } from 'redux-saga/effects'
import { cancel, put, fork, take } from 'redux-saga/effects'

import {
  SEARCH_REQUEST,
  SEARCH_SET,
  SEARCH_SET_RESULT,
} from '../../actions/constants'

/**
 * Search Saga
 * Save data when user entered the small register form to pass it to the register page
 */
export function* searchFlow(input) {
  // use a small delay so we can cancel the task in this period
  yield delay(200)

  if (input) {
    yield put({ type: SEARCH_SET, searchString: input.searchString })
  }
}

/**
 * Search Saga Debounced
 * Debounced Search Saga (see @url https://redux-saga.js.org/docs/recipes/)
 */
export function* searchFlowDebounced() {
  let task
  while (true) {
    const input = yield take(SEARCH_REQUEST)

    if (input.searchString) {
      // put search string to keep all search inputs in sync
      yield put({ type: SEARCH_SET, searchString: input.searchString })
    } else {
      yield put({ type: SEARCH_SET_RESULT, searchResultIds: false })
    }

    if (task) {
      // cancel if there is still an active searchFlow task
      yield cancel(task)
    }
    // start searchFlow
    task = yield fork(searchFlow, input)
  }
}
