import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'
import Dialog from '@material-ui/core/Dialog'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import Slide from '@material-ui/core/Slide'
import classNames from 'classnames'

import ALink from '../01-atoms/a-link'
import ALogo from '../01-atoms/a-logo'

import Logo from '../../assets/svgs/logo.svg'

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    flex: 1,
    marginTop: theme.typography.pxToRem(5),
    justifyContent: 'left',
  },
  link: {
    width: '100%',
    textAlign: 'center',
    textDecoration: 'none',
    fontWeight: '600',
    fontFamily: 'Roboto',
  },
  activeLink: {
    color: theme.palette.highlightButton.main,
  },
  mobileMenuIcon: {
    marginLeft: 'auto',
  },
})

class MMenuDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

    // This binding is necessary to make `this` work in the callback
    this.toggleOpenState = this.toggleOpenState.bind(this)
  }

  Transition(props) {
    return <Slide direction="down" {...props} />
  }

  toggleOpenState() {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    const { classes, entries, buttonEntries, ...other } = this.props
    const menu = entries.map((menuItem, index) => {
      return (
        <React.Fragment key={index}>
          <ListItem component="div" onClick={this.toggleOpenState}>
            <ALink
              className={classes.link}
              activeclassname={classes.activeLink}
              to={menuItem.target}
              noButton
            >
              {menuItem.label}
            </ALink>
          </ListItem>
          <Divider />
        </React.Fragment>
      )
    })

    const menuButtons = buttonEntries.map((button, index) => {
      const changedButton = React.cloneElement(button, {
        className: classNames(button.props.className, classes.link),
      })

      return (
        <React.Fragment key={index}>
          <ListItem component="div" onClick={this.toggleOpenState}>
            {changedButton}
          </ListItem>
        </React.Fragment>
      )
    })

    return (
      <Fragment>
        <IconButton
          color="primary"
          onClick={this.toggleOpenState}
          aria-label="Close"
          className={classes.mobileMenuIcon}
        >
          <MenuIcon />
        </IconButton>
        <Dialog
          keepMounted={true}
          fullScreen
          open={this.state.open}
          TransitionComponent={this.Transition}
          {...other}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <ALogo className={classes.logo} to="/">
                <Logo width="90" height="50" />
              </ALogo>

              <IconButton
                color="primary"
                onClick={this.toggleOpenState}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <List component="div">{menu}</List>
          <List>{menuButtons}</List>
        </Dialog>
      </Fragment>
    )
  }
}

MMenuDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object),
  buttonEntries: PropTypes.arrayOf(PropTypes.object),
}

export default withStyles(styles)(MMenuDialog)
