import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'

/** ALink Component */

class ALink extends Component {
  constructor(props) {
    super(props)

    // eslint-disable-next-line react/display-name
    this.renderLink = React.forwardRef((itemProps, ref) => (
      <Link to={this.props.to} {...itemProps} ref={ref} />
    ))
  }

  render() {
    const { children, to, noButton, activeclassname, ...props } = this.props

    // @url https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-link#for-internal-links-only
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)

    if (noButton && !internal) {
      return (
        <a href={to} {...props} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    }

    // Use gatsby-link for internal links, and <a> for others
    if (internal) {
      if (noButton) {
        return (
          <Link to={to} activeClassName={activeclassname} {...props}>
            {children}
          </Link>
        )
      }
      return (
        <Button
          color="primary"
          component={this.renderLink}
          variant="text"
          {...props}
        >
          {children}
        </Button>
      )
    }
    // remove some props for external links:
    // activeStyle on a regular DOM element would result
    // in a react warning
    const deleteProps = ['activeStyle', 'activeclassname']

    deleteProps.forEach(function(prop) {
      delete props[prop]
    })

    return (
      <Button
        color="primary"
        variant="text"
        href={to}
        target="_blank"
        rel="noopener"
        {...props}
      >
        {children}
      </Button>
    )
  }
}

ALink.propTypes = {
  /** Target of this Link. External links will be opened in a new window. Internal links (starting with /) will use the gatsby-link component. */
  to: PropTypes.string,
  /** The links children nodes */
  children: PropTypes.node,
  /** if true, we don't use the material ui button */
  noButton: PropTypes.bool,
  className: PropTypes.string,
  activeclassname: PropTypes.string,
}

export default ALink
