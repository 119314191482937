import axios from 'axios'

// TODO: set to false for production
axios.defaults.withCredentials = true

/**
 * Mapping function for objects
 * @param  {object} objectToMap     The received data we're mapping
 * @return  {object} Object with raw object and mapped object: { raw: objectToMap, mapped: objectMapped }
 */
const mapToId = objectToMap => {
  let objectMapped = {}

  for (var key in objectToMap) {
    // eslint-disable-next-line no-prototype-builtins
    if (objectToMap.hasOwnProperty(key)) {
      objectMapped[objectToMap[key].id] = objectToMap[key]
    }
  }

  return {
    raw: objectToMap,
    mapped: objectMapped,
  }
}

export default {
  getCompany(companyId, filter) {
    let url = '/api/exhibitor/'
    let singleQuery = false

    switch (typeof companyId) {
      case 'number':
      case 'string':
        url = url + companyId + '/'
        singleQuery = true
        break
      default:
      // console.log('getCompany(), nothing to append to url')
    }

    if (typeof filter == 'object') {
      let filterParams = []
      Object.entries(filter).forEach(element => {
        if (element[0] == 'tags' && element[1].length) {
          filterParams.push('tags=' + element[1].join())
        } else if (element[0] != 'tags' && element[1] !== false) {
          filterParams.push(element[0] + '=' + element[1])
        }
      })

      url = url + (filterParams.length ? '?' + filterParams.join('&') : '')
    }

    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        // only return company as object if we're querying one company via companyId
        return singleQuery
          ? response.data[0]
          : { data: response.data, headers: response.headers }
      })
      .catch(error => {
        // console.log('axios getCompany() fail', error.response)
        return error
      })
  },
  setCompany({ companyId, data, token }) {
    // const { companyId, data } = request

    return axios({
      method: 'put',
      url: '/api/exhibitor/' + companyId + '/',
      headers: {
        'X-Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(response => {
        // only return company as object if we're querying one company via companyId
        return response.data
      })
      .catch(error => {
        return error
      })
  },
  getSearchResults(searchString) {
    return axios({
      method: 'get',
      url: '/search/?q=' + searchString,
    })
      .then(response => {
        return response.data.webPages.value
          .filter(result => result.displayUrl.match('/firma/'))
          .map(result =>
            parseInt(
              result.displayUrl.replace(
                /.*?\/firma\/([0-9]{1,})\/{0,1}/gim,
                '$1'
              )
            )
          )
      })
      .catch(error => {
        return error
      })
  },
  addMedia(request) {
    const { data, token } = request
    return axios({
      method: 'post',
      url: '/api/media/',
      headers: {
        'X-Authorization': 'Bearer ' + token,
      },
      data: data,
    })
      .then(response => {
        return response.data.id
      })
      .catch(error => {
        return error
      })
  },
  deleteMedia(request) {
    const { imageID, token } = request
    return axios({
      method: 'delete',
      url: '/api/media/' + imageID + '/',
      headers: {
        'X-Authorization': 'Bearer ' + token,
      },
    })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  },
  getSettings() {
    return axios({
      method: 'get',
      url: '/api/settings/',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      // console.log('axios getSettings()', response)
      return response.data
    })
  },
  getCategory(categoryID, filter) {
    let url = '/api/category/'

    if (typeof categoryID == 'number') {
      url = url + categoryID + '/'
    }

    if (typeof filter == 'object') {
      let filterParams = []
      Object.entries(filter).forEach(element => {
        if (element[0] == 'tags' && element[1].length) {
          filterParams.push('tags=' + element[1].join())
        } else if (element[0] != 'tags' && element[1] !== false) {
          filterParams.push(element[0] + '=' + element[1])
        }
      })

      url = url + (filterParams.length ? '?' + filterParams.join('&') : '')
    }

    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      // copy categories as mapped object, we need them mapped anyway
      return mapToId(response.data)
    })
  },
  getTag(tagId, filter) {
    let url = '/api/tag/'

    if (typeof tagId == 'number') {
      url = url + tagId + '/'
    }

    if (typeof filter == 'object') {
      let filterParams = []
      Object.entries(filter).forEach(element => {
        if (element[0] == 'tags' && element[1].length) {
          filterParams.push('tags=' + element[1].join())
        } else if (element[0] != 'tags' && element[1] !== false) {
          filterParams.push(element[0] + '=' + element[1])
        }
      })

      url = url + (filterParams.length ? '?' + filterParams.join('&') : '')
    }

    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      // console.log('axios getTag()', response)
      // copy tags as mapped object, we need them mapped anyway
      return mapToId(response.data)
    })
  },
  getLocations(filter) {
    let url = '/api/locations/'

    if (typeof filter == 'object') {
      let filterParams = []
      Object.entries(filter).forEach(element => {
        if (element[0] == 'tags' && element[1].length) {
          filterParams.push('tags=' + element[1].join())
        } else if (element[0] != 'tags' && element[1] !== false) {
          filterParams.push(element[0] + '=' + element[1])
        }
      })

      url = url + (filterParams.length ? '?' + filterParams.join('&') : '')
    }

    return axios({
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      return response.data
    })
  },
  setPassword(data) {
    return axios({
      method: 'post',
      url: '/api/user/password/set/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },
  resetPassword(data) {
    return axios({
      method: 'post',
      url: '/api/user/password/reset/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },
  changePassword(request) {
    const { data, userId, userToken } = request

    return axios({
      method: 'post',
      url: '/api/user/' + userId + '/',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': 'Bearer ' + userToken,
      },
      data: data,
    })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },
  mailRequest(request) {
    const { data, userId, userToken } = request

    return axios({
      method: 'post',
      url: '/api/mail/' + userId + '/',
      headers: {
        'Content-Type': 'application/json',
        'X-Authorization': 'Bearer ' + userToken,
      },
      data: data,
    })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  },
}
